import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less'],
})
export class PaginationComponent implements OnInit {
  @Input()
  pages: number[] = [];

  @Input()
  offset = 0;

  @Input()
  count = 0;

  @Output()
  onSelect: EventEmitter<number> = new EventEmitter();

  @Output()
  onOffsetChange: EventEmitter<number> = new EventEmitter();

  selected = 1;
  isPreviousDisabled = true;
  isNextDisabled = false;
  private readonly allPagesLimit = 100;

  constructor() {}

  ngOnInit(): void {
    this.handleActions();
  }

  onSelectPage(page: number) {
    this.selected = page;
    this.onSelect.emit(page);
  }

  onPreviousOffset(): void {
    if (this.offset <= 0) {
      return;
    }
    this.offset--;
    this.handleActions();
    this.onOffsetChange.emit(this.offset);
  }

  onNextOffset(): void {
    this.offset++;
    this.handleActions();
    this.onOffsetChange.emit(this.offset);
  }

  private handleActions(): void {
    this.isPreviousDisabled = this.offset === 0;
    this.isNextDisabled =
      Math.floor(this.count / this.allPagesLimit) <= this.offset;
  }
}
