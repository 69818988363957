import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { isArray, isObject } from 'lodash';

enum ILevel {
  Level = 'Level',
  Message = 'Message',
  Data = 'Data',
  Error = 'Error',
}

interface ILog {
  [ILevel.Level]: string;
  [ILevel.Message]: string;
  [ILevel.Data]: string;
  [ILevel.Error]: string;
}

interface ILogResponse {
  time: string;
  log: ILog;
  show?: boolean;
}

interface ILogsResponse {
  count: number;
  logs: ILogResponse[];
  keys: string[];
  status: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  allLogs: ILogResponse[] = [];
  logs: ILogResponse[] = [];
  keys: string[] = [];
  loading = true;
  limit = 10;
  offset = 0;
  page = 0;
  pages = [];
  logsCount = 0;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.getLogs();
  }

  async getLogs() {
    this.loading = true;
    try {
      const url = `${environment.apiUrl}logger?offset=${this.offset}`;
      this.http
        .get<ILogsResponse>(url)
        .toPromise()
        .then((response: ILogsResponse) => {
          this.logsCount = response.count;
          this.allLogs = response.logs.reverse();
          this.keys = response.keys;
          this.initLogs();
          this.initPages();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error?.message);
        });
    } catch (e) {
      this.loading = false;
      console.log(e.error?.message);
    }
  }

  initPages() {
    this.pages = [];
    const pages = Math.floor(this.allLogs.length / this.limit);
    const extra = Math.min(1, this.allLogs.length % this.limit);
    const count = pages + extra;
    for (let i = 1; i <= count; i++) {
      this.pages.push(i);
    }
  }

  initLogs() {
    this.page = 0;
    this.setLogs();
  }

  setLogs() {
    const start = this.page * this.limit;
    const end = (this.page + 1) * this.limit;
    this.logs = this.allLogs.slice(start, end);
  }

  onSelectPage(page: number) {
    this.page = page - 1;
    this.setLogs();
  }

  toggleContainer() {}

  isArrayParam(param) {
    return isArray(param);
  }

  isObjectParam(param) {
    return isObject(param);
  }

  onOffsetChange(offest: number) {
    this.offset = offest;
    this.getLogs();
  }
}
