<div class="pages">
  <div class="offset">Offset: {{ offset }}</div>
  <div
    class="offset previous"
    [class.disabled]="isPreviousDisabled"
    (click)="onPreviousOffset()"
  >
    &lt;
  </div>
  <div
    class="page"
    *ngFor="let page of pages"
    (click)="onSelectPage(page)"
    [ngClass]="{ selected: page === selected }"
  >
    {{ page }}
  </div>
  <div
    class="offset next"
    [class.disabled]="isNextDisabled"
    (click)="onNextOffset()"
  >
    &gt;
  </div>
</div>
