<app-loader *ngIf="loading"></app-loader>
<div class="container" *ngIf="!loading">
  <table class="logs-table" border="1" cellspacing="0">
    <tbody>
      <tr>
        <th>Time</th>
        <th *ngFor="let key of keys">{{ key }}</th>
      </tr>
      <tr *ngFor="let log of logs">
        <td>{{ log.time }}</td>
        <td *ngFor="let key of keys">
          <div
            *ngIf="
              !isArrayParam(log.log[key]) && !isObjectParam(log.log[key]);
              else isObject
            "
          >
            <span>{{ log.log[key] }}</span>
          </div>
          <ng-template #isObject>
            <div
              class="object-container"
              (click)="log.show = !log.show"
              [ngClass]="{ opened: !!log.show }"
            >
              <pre>{{ log.log[key] | json }}</pre>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>

  <app-pagination
    [pages]="pages"
    [offset]="offset"
    [count]="logsCount"
    (onSelect)="onSelectPage($event)"
    (onOffsetChange)="onOffsetChange($event)"
  ></app-pagination>
</div>
